var generic = generic || {};
var site = site || {};

(function ($, Drupal) {
  'use strict';

  /**
   * Trigger youtube player
   */
  var collectionVideoPlayerV1 = {
    createVideoPlayerDiv: function ($elem, i) {
      var id = $elem.attr('class') + '-' + i;

      $elem
        .parent()
        .append('<div />')
        .children('div')
        .attr('id', id);
      $elem.remove(); // Remove the placeholder element

      return id;
    },

    addVideo: function (ytId, iframeId) {
      /* global YT */
      var player = new YT.Player(iframeId, {
        height: '100%',
        width: '100%',
        videoId: ytId,
        playerVars: {
          controls: 1,
          showinfo: 0,
          rel: 0
        },
        events: {
          'onReady': function (event) {
            // Detect mobile
            // Taken from - http://stackoverflow.com/a/13819253
            var isMobile = {
              Android: function () {
                return navigator.userAgent.match(/Android/i);
              },
              BlackBerry: function () {
                return navigator.userAgent.match(/BlackBerry/i);
              },
              iOS: function () {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
              },
              Opera: function () {
                return navigator.userAgent.match(/Opera Mini/i);
              },
              Windows: function () {
                return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
              },
              any: function () {
                return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows();
              }
            };

            // Don't autoplay on any mobile device
            if (!isMobile.any()) {
              event.target.playVideo();

              if (typeof event.target.playerInfo !== 'undefined' && typeof event.target.playerInfo.videoData !== 'undefined' && typeof event.target.playerInfo.videoData.title !== 'undefined' && typeof event.target.getDuration !== 'undefined') {
                var title = event.target.playerInfo.videoData.title || '';
                var duration = event.target.getDuration() || 0;

                // Track opening of video
                if (typeof site.track !== 'undefined' && typeof site.track.evtLink === 'function') {
                  site.track.evtLink({
                    event_name: 'video',
                    event_category: 'video',
                    event_action: 'collection video - image holder',
                    event_label: title + ' - ' + ytId,
                    video_id: ytId,
                    video_length: Math.round(duration).toString(),
                    video_name: title,
                    video_platform: 'YouTube'
                  });
                }
              }
            }
          },
          'onStateChange': function (event) {
            // stuff
          }
        }
      });
    },

    setup: function (event) {
      var self = this;

      $('.js-collection-video-player__image').each(function () {
        var $img = $(this);
        var img = new Image();

        img.src = $img.attr('src');
        img.onload = function () {
          $img.attr('width', img.naturalWidth);
          $img.attr('height', img.naturalHeight);
        };
      });

      $('.js-collection-video-player').each(function (i) {
        var $me = $(this),
          $content = $('.collection-video-player__content', $me),
          $vids = $content.filter(function (i) {
            return $(this).data('video-provider') === 'youtube' && $(this).data('youtube-id').length > 0;
          }),
          $playIcon = $('.collection-video-player__media .js-play', $content),
          $playIconBtn = $('i.js-play', $content);

        $vids.each(function () {
          var $wrap = $(this),
            ytId = $wrap.attr('data-youtube-id');

          $playIcon.keydown(function (e) {
            if (site.getKeycode(e) === 13) {
              $playIcon.trigger('click');
            }
          });

          $playIcon.click(function (e) {
            // Do something useless
            e.preventDefault();

            if ($playIconBtn.length) {
              $playIconBtn.addClass('hidden');
            }

            // Save scroll position, the colorbox causes a jump
            var scrollPosition = $(window).scrollTop();
            // Detect if we want an inverted colored close icon or not
            var classList = (function () {
              if ($wrap.hasClass('js-invert-close')) {
                return 'overlay--collection-video inverted-close';
              }

              return 'overlay--collection-video';
            })();

            if ($wrap.hasClass('js-play-within-frame')) {
              var videoContainer = $wrap.find('.js-collection-video-placeholder');
              // Create a new div for the player to take over inside of the colorbox
              var iframeId = self.createVideoPlayerDiv(videoContainer, Math.floor(+new Date()) / 1000);

              // Instantiate the video
              self.addVideo(ytId, iframeId);
            } else {
              // Launch the overlay
              generic.overlay.launch({
                content: '<div class="js-collection-video-placeholder"></div>', // Empty div for selection that gets removed
                transition: 'none',
                fixed: 'true',
                appearDuration: 1.0,
                opacity: 0,
                maxWidth: '100%',
                maxHeight: '100%',
                overflow: 'hidden',
                cssStyle: {
                  border: 'none',
                  padding: 0,
                  backgroundColor: '#000',
                  height: '100%',
                  width: '100%',
                  position: 'fixed',
                  className: classList,
                  onComplete: function () {
                    var _this = this;

                    _this.$cbox = $('#colorbox.overlay--collection-video');

                    // Create a new div for the player to take over inside of the colorbox
                    var iframeId = self.createVideoPlayerDiv($('.js-collection-video-placeholder', _this.$cbox), Math.floor(+new Date()) / 1000);

                    // Instantiate the video
                    self.addVideo(ytId, iframeId);

                    // Animate in
                    _this.$cbox.addClass('active');

                    // Hide overflow on the body
                    setTimeout(function () {
                      $('body').css({
                        overflow: 'hidden',
                        height: $(window).height()
                      });
                    }, 1000);
                  },
                  onCleanup: function () {
                    var _this = this;

                    _this.$cbox.removeClass('active');

                    $('body').css({
                      overflow: '',
                      height: ''
                    });
                    $(window).scrollTop(scrollPosition);
                  }
                }
              });
            }
            // Make responsive
            $(window).off('resize.collectionVideoResize').on('resize.collectionVideoResize', function () {
              $.colorbox.resize({
                width: '100%',
                height: '100%'
              });
            });

            // Do something else that's useless
            return false;
          });
        });
      });
    }
  };

  $(document).bind('youtubeIframeAPI.loaded', function (e) {
    collectionVideoPlayerV1.setup(e);
  });
})(jQuery, Drupal);
